import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  RentHero,
  RentStepByStep,
  AboutOptions,
  SinglePropertyAdvice,
} from 'components/sections';
import { SingleImageSection } from 'components/blocks';
import rentPageMotion from 'motion/rent.js';

import { ViewportEnterAnimation } from 'components/ui';
import { functionSinglePropertyAdvice, functionTimeline } from 'motion/rent.js';
import { functionAboutAccordion } from 'motion/about.js';
import { tripleImageSectionScroll } from 'motion/buy.js';

class RentPage extends PureComponent {
  componentDidMount() {
    const enquiryType =
      this.props.data.pages.propertySearchForm.offerTypes.lettings
      || 'Lettings';

    // Set enquiry type to Sales on /buy
    if (typeof window !== 'undefined') {
      window.directStateMutate({
        enquiryType,
      });
    }
  }

  ctaClick = () => {
    if (typeof window !== 'undefined') {
      window.openEnquiry && window.openEnquiry();
    }
  }

  render() {
    const { data } = this.props;
    const { propertySearchForm, rent } = data.pages;

    const { pageTitle, faq, landing, highlight, stepsData, advice, seo } = rent;

    const  img1 = {src:highlight.img1, height:314, width: 456};
    const  img2 = {src:highlight.img2, height:386, width: 346};
    const  img3 = {src:highlight.img3, height:649, width: 416};

    return (
      <Layout onVisible={rentPageMotion}>
        <SEO title={seo.title} description={seo.meta} keywords={seo.keywords} />

        <RentHero
          content={landing}
          propertySearchForm={propertySearchForm}
        />
        <ViewportEnterAnimation offset={100} animation={functionTimeline}>
          <RentStepByStep {...stepsData} />
        </ViewportEnterAnimation>

        <ViewportEnterAnimation offset={100} animation={tripleImageSectionScroll}>
          <SingleImageSection
            content={highlight}
            buttonAction={() => console.log('lets discuss')}
            imageBack={img1}
            imageMiddle={img2}
            imageFront={img3}

          imageBackalt={highlight.img1alt}
          imageMiddlealt={highlight.img2alt}                     
          imageFrontalt={highlight.img3alt}
          />
        </ViewportEnterAnimation>
        <ViewportEnterAnimation offset={100} animation={functionAboutAccordion}>
          <AboutOptions name="rent-page-faq" data={faq} />
        </ViewportEnterAnimation>

        <ViewportEnterAnimation offset={100} animation={functionSinglePropertyAdvice}>
          <SinglePropertyAdvice onClick={this.ctaClick} content={advice} />
        </ViewportEnterAnimation>

      </Layout>
    );
  }
}

export const query = graphql`
  query RentPage {
    pages {
      propertySearchForm {
        title
        offerTypes {
          lettings
          sales
        }
        defaultCost {
          min
          max
        }
        form {
          labels {
            area
            apartment
            bedrooms
            includeNewHomes
            searchBtn
          }
          selects {
            apartment {
              value
              text
            }
            bedrooms {
              value
              text
            }
          }
        }
      }
      rent {
        pageTitle
        landing {
          title
          bodyText
          img
        }
        highlight {
          title
          paragraph1
          paragraph2
          callToAction
          img1
          img2
          img3
          img1alt
          img2alt
          img3alt
        }
        stepsData {
          title
          steps {
            title
            content
          }
        }
        advice {
          title
          subtitle
          btn
          img
        }
        faq {
          title
          questions {
            question
            answer
          }
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;

export default RentPage;
